var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "reworkersManage-wrapper" },
    [
      _c("list", {
        ref: "list",
        attrs: {
          exportPermission: "export",
          searchUrl: _vm.searchUrl,
          searchParams: _vm.searchParams,
          headers: _vm.headers,
          isMultiSelect: true,
        },
        on: {
          "update:searchParams": function ($event) {
            _vm.searchParams = $event
          },
          "update:search-params": function ($event) {
            _vm.searchParams = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "headerSlot",
            fn: function () {
              return [
                _c("v-button", {
                  attrs: { text: "导入", permission: "import" },
                  on: { click: _vm.goPageImport },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "searchSlot",
            fn: function () {
              return [
                _c("v-input", {
                  attrs: { label: "姓名" },
                  model: {
                    value: _vm.searchParams.userName,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "userName", $$v)
                    },
                    expression: "searchParams.userName",
                  },
                }),
                _c("v-input", {
                  attrs: { label: "手机号" },
                  model: {
                    value: _vm.searchParams.mobileNum,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "mobileNum", $$v)
                    },
                    expression: "searchParams.mobileNum",
                  },
                }),
                _c(
                  "v-select2",
                  _vm._b(
                    {
                      attrs: { label: "所属企业" },
                      model: {
                        value: _vm.searchParams.orgId,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "orgId", $$v)
                        },
                        expression: "searchParams.orgId",
                      },
                    },
                    "v-select2",
                    _vm.orgParams,
                    false
                  )
                ),
                _c(
                  "v-select2",
                  _vm._b(
                    {
                      attrs: { label: "所属项目", placeholder: "查询项目" },
                      model: {
                        value: _vm.searchParams.communityId,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "communityId", $$v)
                        },
                        expression: "searchParams.communityId",
                      },
                    },
                    "v-select2",
                    _vm.communityParams,
                    false
                  )
                ),
                _c("v-select", {
                  attrs: { label: "复工状态", options: _vm.statusOpts },
                  model: {
                    value: _vm.searchParams.resumptionWorkApproveStatus,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.searchParams,
                        "resumptionWorkApproveStatus",
                        $$v
                      )
                    },
                    expression: "searchParams.resumptionWorkApproveStatus",
                  },
                }),
                _c("v-select", {
                  attrs: { label: "是否完善信息", options: _vm.infoStatusOpts },
                  model: {
                    value: _vm.searchParams.isCompleteInfo,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "isCompleteInfo", $$v)
                    },
                    expression: "searchParams.isCompleteInfo",
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "operateSlot",
            fn: function (scope) {
              return [
                scope.row.resumptionWorkApproveStatus === 0 &&
                scope.row.healthStatus === 1
                  ? _c("v-button", {
                      attrs: {
                        text: "确认复工",
                        type: "text",
                        permission: "confimResumption",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.handleStatus(scope.row)
                        },
                      },
                    })
                  : _vm._e(),
                scope.row.resumptionWorkApproveStatus === 1
                  ? _c("v-button", {
                      attrs: {
                        text: "取消复工",
                        type: "text",
                        permission: "cancelResumption",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.handleStatus(scope.row)
                        },
                      },
                    })
                  : _vm._e(),
                scope.row.isCompleteInfo === 0
                  ? _c("v-button", {
                      attrs: {
                        text: "审核",
                        type: "text",
                        permission: "healthApprove",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.openDialog(scope.row)
                        },
                      },
                    })
                  : _vm._e(),
              ]
            },
          },
          {
            key: "batchSlot",
            fn: function (scope) {
              return [
                _c("v-button", {
                  attrs: { text: "批量复工", permission: "confimResumption" },
                  on: {
                    click: function ($event) {
                      return _vm.batchHandle(scope.selectedData, 1)
                    },
                  },
                }),
                _c("v-button", {
                  attrs: { text: "批量取消", permission: "cancelResumption" },
                  on: {
                    click: function ($event) {
                      return _vm.batchHandle(scope.selectedData, 2)
                    },
                  },
                }),
              ]
            },
          },
        ]),
      }),
      _c(
        "el-dialog",
        {
          staticClass: "info-dialog",
          attrs: { title: "审核", visible: _vm.dialogVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { "label-width": "200px" } },
            [
              _c("el-form-item", { attrs: { label: "姓名：" } }, [
                _vm._v(
                  "\n        " + _vm._s(_vm.userInfo.userName) + "\n      "
                ),
              ]),
              _c("el-form-item", { attrs: { label: "性别：" } }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.userInfo.sex === 1 ? "男" : "女") +
                    "\n      "
                ),
              ]),
              _c("el-form-item", { attrs: { label: "身份证号：" } }, [
                _vm._v(
                  "\n        " + _vm._s(_vm.userInfo.cardNum) + "\n      "
                ),
              ]),
              _c("el-form-item", { attrs: { label: "户籍所在地：" } }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.userInfo.registeredResidence) +
                    "\n      "
                ),
              ]),
              _c("el-form-item", { attrs: { label: "现居住地：" } }, [
                _vm._v(
                  "\n        " + _vm._s(_vm.userInfo.homeAddress) + "\n      "
                ),
              ]),
              _c("el-form-item", { attrs: { label: "公司所在区：" } }, [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.userInfo.communityProvince +
                        _vm.userInfo.communityCity +
                        _vm.userInfo.communityArea
                    ) +
                    "\n      "
                ),
              ]),
              _c("el-form-item", { attrs: { label: "公司所在园区/楼宇：" } }, [
                _vm._v(
                  "\n        " + _vm._s(_vm.userInfo.communityName) + "\n      "
                ),
              ]),
              _c("el-form-item", { attrs: { label: "公司名称：" } }, [
                _vm._v(
                  "\n        " + _vm._s(_vm.currentUser.orgName) + "\n      "
                ),
              ]),
              _vm.userInfo.isCardImgRequired === 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "身份证照片：" } },
                    [_c("v-preview", { attrs: { imgs: _vm.cardImgs } })],
                    1
                  )
                : _vm._e(),
              _vm.userInfo.isHealthImgRequired === 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "个人照片：" } },
                    [_c("v-preview", { attrs: { imgs: _vm.healthImgs } })],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "danger" },
                  on: {
                    click: function ($event) {
                      return _vm.handleApprove(2)
                    },
                  },
                },
                [_vm._v("拒 绝")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handleApprove(1)
                    },
                  },
                },
                [_vm._v("通 过")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }