<template>
  <div class="reworkersManage-wrapper">
    <list
      ref="list"
      exportPermission="export"
      :searchUrl="searchUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
      :isMultiSelect="true"
    >
      <template #headerSlot>
        <v-button text="导入" permission="import" @click="goPageImport" />
      </template>
      <template #searchSlot>
        <v-input label="姓名" v-model="searchParams.userName"></v-input>
        <v-input label="手机号" v-model="searchParams.mobileNum"></v-input>
        <v-select2 v-model="searchParams.orgId" label="所属企业"  v-bind="orgParams" />
        <v-select2 label="所属项目" placeholder="查询项目" v-model="searchParams.communityId" v-bind="communityParams" />
        <v-select label="复工状态" v-model="searchParams.resumptionWorkApproveStatus" :options="statusOpts"></v-select>
        <v-select label="是否完善信息" v-model="searchParams.isCompleteInfo" :options="infoStatusOpts"></v-select>
      </template>
      <template #operateSlot="scope">
        <v-button
          v-if="scope.row.resumptionWorkApproveStatus === 0 && scope.row.healthStatus === 1"
          text="确认复工"
          type="text"
          permission="confimResumption"
          @click="handleStatus(scope.row)"
        />
        <v-button
          v-if="scope.row.resumptionWorkApproveStatus === 1"
          text="取消复工"
          type="text"
          permission="cancelResumption"
          @click="handleStatus(scope.row)"
        />
        <v-button
          v-if="scope.row.isCompleteInfo === 0"
          text="审核"
          type="text"
          permission="healthApprove"
          @click="openDialog(scope.row)"
        />
      </template>
      <template #batchSlot="scope">
        <v-button
          text="批量复工"
          permission="confimResumption"
          @click="batchHandle(scope.selectedData, 1)"
        ></v-button>
        <v-button
          text="批量取消"
          permission="cancelResumption"
          @click="batchHandle(scope.selectedData, 2)"
        ></v-button>
      </template>
    </list>
    <el-dialog title="审核" :visible.sync="dialogVisible" class="info-dialog">
      <el-form label-width="200px">
        <el-form-item label="姓名：">
          {{ userInfo.userName }}
        </el-form-item>
        <el-form-item label="性别：">
          {{ userInfo.sex === 1 ? '男' : '女' }}
        </el-form-item>
        <el-form-item label="身份证号：">
          {{ userInfo.cardNum }}
        </el-form-item>
        <el-form-item label="户籍所在地：">
          {{ userInfo.registeredResidence }}
        </el-form-item>
        <el-form-item label="现居住地：">
          {{ userInfo.homeAddress }}
        </el-form-item>
        <el-form-item label="公司所在区：">
          {{ userInfo.communityProvince + userInfo.communityCity + userInfo.communityArea }}
        </el-form-item>
        <el-form-item label="公司所在园区/楼宇：">
          {{ userInfo.communityName }}
        </el-form-item>
        <el-form-item label="公司名称：">
          {{ currentUser.orgName }}
        </el-form-item>
        <el-form-item v-if="userInfo.isCardImgRequired === 1" label="身份证照片：">
          <v-preview :imgs="cardImgs"></v-preview>
        </el-form-item>
        <el-form-item v-if="userInfo.isHealthImgRequired === 1" label="个人照片：">
          <v-preview :imgs="healthImgs"></v-preview>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="danger" @click="handleApprove(2)">拒 绝</el-button>
        <el-button type="primary" @click="handleApprove(1)">通 过</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Dialog } from 'element-ui'
import { communityParams, orgParams } from '@/common/select2Params'
import { vPreview } from 'components/control'
import { getListURL, approveReworkURL, getUserInfoURL, approveUserURL } from './api'
import {
  statusMap,
  statusOpts,
  healthStatusMap,
  infoStatusMap,
  infoStatusOpts
} from './map'

export default {
  name: 'reworkersManageList',
  components: {
    [Dialog.name]: Dialog,
    vPreview
  },
  data () {
    return {
      searchUrl: getListURL,
      statusOpts,
      infoStatusOpts,
      orgParams,
      communityParams,
      dialogVisible: false,
      searchParams: {
        userName: '',
        mobileNum: '',
        orgId: '',
        communityId: '',
        resumptionWorkApproveStatus: undefined,
        isCompleteInfo: undefined
      },
      headers: [
        {
          prop: 'userName',
          label: '姓名'
        },
        {
          prop: 'mobileNum',
          label: '手机号'
        },
        {
          prop: 'orgName',
          label: '所属企业'
        },
        {
          prop: 'communityName',
          label: '所属项目'
        },
        {
          prop: 'currentResumptionUserCount',
          label: '复工人数（当前/上限）',
          formatter (row) {
            return `${row.currentResumptionUserCount}/${row.allResumptionUserCount}`
          }
        },
        {
          prop: 'resumptionWorkApproveStatus',
          label: '复工状态',
          formatter (row) {
            return statusMap[row.resumptionWorkApproveStatus]
          }
        },
        {
          prop: 'healthStatus',
          label: '是否符合要求',
          formatter (row) {
            return healthStatusMap[row.healthStatus]
          }
        },
        {
          prop: 'infoStatus',
          label: '是否完善信息',
          formatter (row) {
            return infoStatusMap[row.isCompleteInfo]
          }
        },
        {
          prop: 'updateTime',
          label: '复工时间'
        }
      ],
      currentUser: {},
      userInfo: {},
      cardImgs: [],
      healthImgs: []
    }
  },
  methods: {
    goPageImport () {
      this.$router.push({
        name: 'reworkersImport'
      })
    },
    async handleStatus (row) {
      let tips = '确定允许复工吗？'
      let type = 1
      if (row.resumptionWorkApproveStatus === 1) {
        tips = '确定取消复工吗？'
        type = 2
      }
      if (await this.$confirm(tips)) {
        this.$axios.post(approveReworkURL, {
          approveType: type,
          userInfoFList: [
            {
              orgId: row.orgId,
              userId: row.id
            }
          ]
        }).then(res => {
          if (res.status === 100) {
            this.$refs.list.searchData()
          }
        })
      }
    },
    openDialog (row) {
      this.dialogVisible = true
      this.currentUser = row
      this.getUserInfo(row)
    },
    // 获取用户详情
    async getUserInfo (row) {
      this.userInfo = {}
      let res = await this.$axios.get(getUserInfoURL, {
        params: {
          userId: row.userId,
          orgId: row.orgId,
          communityId: row.communityId
        }
      })
      if (res.status === 100) {
        this.userInfo = res.data
        this.cardImgs = [res.data.cardImg, res.data.cardBackImg]
        this.healthImgs = [res.data.healthImg, res.data.healthImgMask]
      }
    },
    // 审核用户资料
    async handleApprove (status) {
      let tips = ''
      if (status === 1) {
        tips = '确认审核通过？'
      } else {
        tips = '确认审核不通过？'
      }
      if (await this.$confirm(tips)) {
        this.$axios.post(`${approveUserURL}?id=${this.currentUser.id}&status=${status}`).then(res => {
          if (res.status === 100) {
            this.dialogVisible = false
            this.$message.success('操作成功')
            this.$refs.list.searchData()
          }
        })
      }
    },
    // 批量操作
    async batchHandle (selected, status) {
      let ids = selected.ids
      let tips = ''
      let temp = []
      if (ids.length) {
        selected.data.forEach(item => {
          temp.push({
            orgId: item.orgId,
            userId: item.id
          })
        })
        if (status === 1) {
          tips = '确定允许复工吗？'
        } else {
          tips = '确定取消复工吗？'
        }
        if (await this.$confirm(tips)) {
            this.$axios.post(approveReworkURL, {
              approveType: status,
              userInfoFList: temp
            }).then(res => {
              if (res.status === 100) {
                this.$refs.list.searchData()
              }
            })
          }
      } else {
        this.$message({
          type: 'error',
          message: '请先选择要操作的数据',
          center: true
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.dialog-footer {
  display: flex;
  justify-content: space-around;
}
.info-dialog {
  text-align: left;
}
</style>
