import { generateMapByOpts } from '@/common/utils'
// 复工状态
const statusOpts = [
  {
    text: '全部',
    value: undefined
  },
  {
    text: '已复工',
    value: 1
  },
  {
    text: '可以复工',
    value: 0
  }
]

// 健康状态
const healthStatusOpts = [
  {
    text: '全部',
    value: undefined
  },
  {
    text: '符合要求',
    value: 1
  },
  {
    text: '不符合要求',
    value: 2
  }
]

const infoStatusOpts = [
  {
    text: '全部',
    value: undefined
  },
  {
    text: '已完善',
    value: 1
  },
  {
    text: '待审核',
    value: 0
  },
  {
    text: '未完善',
    value: -1
  }
]

const statusMap = generateMapByOpts(statusOpts)
const healthStatusMap = generateMapByOpts(healthStatusOpts)
const infoStatusMap = generateMapByOpts(infoStatusOpts)

export {
  statusMap,
  statusOpts,
  healthStatusMap,
  healthStatusOpts,
  infoStatusMap,
  infoStatusOpts
}
